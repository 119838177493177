import { type Permissions } from '~/api'

export function hasWrite<T extends { permissions?: Permissions }>(
  item: T,
): boolean {
  return item.permissions?.write ?? false
}

export const supportedAuthProviders = ['ns', 'okta'] as const
export type AuthProvider = (typeof supportedAuthProviders)[number]
